<template>
  <div>
    <v-btn
      icon
      @click="colorSelected = colorValue"
    >
      <v-icon
        v-if="colorValue === colorSelected"
        :color="colorValue"
      >
        {{$icon('i.CheckedCircleOutline')}}
      </v-icon>
      <v-icon
        v-else
        :color="colorValue"
      >
        {{$icon('i.Circle')}}
      </v-icon>
    </v-btn>
  </div>
</template>

<script>

export default {
  computed: {
    colorSelected: {
      get () {
        return this.selected
      },
      set (value) {
        this.$emit('update:selected', value)
      }
    }
  },
  props: {
    selected: String,
    colorValue: String
  }
}
</script>

<style lang="stylus"></style>
